import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
import HomeView from '../views/public/HomeView.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/inicio'
    },
    {
        path: '/inicio',
        name: 'HomeView',
        component: HomeView,
        meta: {
            private: false
        }
    },
    {
        path: '/nosotros',
        name: 'AboutView',
        component: () =>
            import(
                /* webpackChunkName: "about-view" */ '../views/public/AboutView.vue'
            ),
        meta: {
            private: false
        }
    },
    {
        path: '/area-socio',
        name: 'AreaSocioView',
        component: () =>
            import(
                /* webpackChunkName: "area-socio-view" */ '../views/public/AreaSocioView.vue'
            ),
        meta: {
            private: false
        }
    },
    {
        path: '/aula-experiencia',
        name: 'AulaExperienciaView',
        component: () =>
            import(
                /* webpackChunkName: "aula-experiencia-view" */ '../views/public/AulaExperienciaView.vue'
            ),
        meta: {
            private: false
        }
    },
    {
        path: '/cursos-extension',
        name: 'CursosExtensionView',
        component: () =>
            import(
                /* webpackChunkName: "cursos-extension-view" */ '../views/public/CursosExtensionView.vue'
            ),
        meta: {
            private: false
        }
    },
    {
        path: '/actividades-y-talleres',
        name: 'ActividadesTalleresView',
        component: () =>
            import(
                /* webpackChunkName: "aactividades-talleres-view" */ '../views/public/ActividadesTalleresView.vue'
            ),
        meta: {
            private: false
        }
    },
    {
        path: '/contacto',
        name: 'ContactoView',
        component: () =>
            import(
                /* webpackChunkName: "contacto-view" */ '../views/public/ContactoView.vue'
            ),
        meta: {
            private: false
        }
    },
    {
        path: '/hazte-socio',
        name: 'HazteSocioView',
        component: () =>
            import(
                /* webpackChunkName: "hazte-socio-view" */ '../views/public/HazteSocioView.vue'
            ),
        meta: {
            private: false
        }
    },

    //! Ácceso sólo a través del área privada
    {
        path: '/nuestra-organizacion',
        name: 'DocumentacionPvtView',
        component: () =>
            import(
                /* webpackChunkName: "documentacion-pvt-view" */ '../views/private/DocumentacionPvtView.vue'
            ),
        meta: {
            private: true
        }
    },
    {
        path: '/actividades-talleres-socio',
        name: 'ActividadesTalleresPvtView',
        component: () =>
            import(
                /* webpackChunkName: "actividades-pvt-view" */ '../views/private/ActividadesTalleresPvtView.vue'
            ),
        meta: {
            private: true
        }
    },
    {
        path: '/cursos-socio',
        name: 'CursosPvtView',
        component: () =>
            import(
                /* webpackChunkName: "cursos-pvt-view" */ '../views/private/CursosPvtView.vue'
            ),
        meta: {
            private: true
        }
    },
    {
        path: '/galeria-imagenes',
        name: 'GaleriaPvtView',
        component: () =>
            import(
                /* webpackChunkName: "galeria-pvt-view" */ '../views/private/GaleriaPvtView.vue'
            ),
        meta: {
            private: true
        }
    },
    {
        path: '/blog-asociacion',
        name: 'BlogAsociacionPvtView',
        component: () =>
            import(
                /* webpackChunkName: "blog-asociacion-pvt-view" */ '../views/private/BlogAsociacionPvtView.vue'
            ),
        meta: {
            private: true
        }
    },
    {
        path: '/plan-actividades-mensuales',
        name: 'PlanActividadesMensualesPvtView',
        component: () =>
            import(
                /* webpackChunkName: "plan-actividades-mensuales-view" */ '../views/private/PlanActividadesMensualesPvtView.vue'
            ),
        meta: {
            private: true
        }
    },
    {
        path: '/enlaces-interes',
        name: 'EnlacesInteresPvtView',
        component: () =>
            import(
                /* webpackChunkName: "enlaces-interes-view" */ '../views/private/EnlacesInteresPvtView.vue'
            ),
        meta: {
            private: true
        }
    },
    {
        path: '/contacto-socio',
        name: 'ContactoSocioPvtView',
        component: () =>
            import(
                /* webpackChunkName: "contacto-socio-view" */ '../views/private/ContactoSocioPvtView.vue'
            ),
        meta: {
            private: true
        }
    }
];

const router = new VueRouter({
    routes,
    //mode: 'history'
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.private) {
        if (!store.getters.socioAuth) {
            next({ name: 'HomeView' });
        } else {
            next();
        }
    } else {
        store.dispatch('mutateState', {
            prop: 'socioAuth',
            value: false
        });

        next();
    }
});

export default router;
