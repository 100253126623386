export const parseDateData = (rawDate) => {
  const date = new Date(rawDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);

  return formattedDate.toUpperCase();
}

export const monthAndYearFromDate = (rawDate) => {
  const date = new Date(rawDate);
  const options = { year: 'numeric', month: 'long' };
  const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);
  const formattedDateFirstLetterUpper = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  return formattedDateFirstLetterUpper;
}
